import React from 'react'
import { Layout, SEO } from 'components/common'
import Head from 'components/theme/Head'
import { StaticImage } from 'gatsby-plugin-image'
import { Footer } from 'components/theme'
import { ModalContainer } from 'reoverlay'
import Container from 'components/common/Container'

import { usePartnerData } from 'hooks/UsePartnerData'

const Thanks = () => {
  const isBrowser = () => typeof window !== 'undefined'
  const urlSearchParams = isBrowser() && new URLSearchParams(window.location.search)
  const params = isBrowser() && Object.fromEntries(urlSearchParams.entries())
  const { fname, pname } = params
  const { legalName, acronym, contact } = usePartnerData()

  const seoTitle = `Thank you ${pname || fname}`
  let starterSentence = null
  if (pname || fname) {
    starterSentence = `${pname || fname}, thanks`
  } else {
    starterSentence = 'Thanks'
  }
  return (
    <>
      <Layout>
        <SEO title={seoTitle} />
        <Head />
        <main>
          <div id="thanks-page">
            <Container className="intro-section" constraints="centered">
              <div className="intro-thanks">
                <h1>
                  {starterSentence} for your interest in {legalName}!{' '}
                </h1>
                <p>
                  We can’t wait for you to see how we do. From future-focused academics that invigorate to hands-on
                  learning that lets you dive in headfirst to our friendly and vibrant community, there’s a lot that
                  makes {acronym} special.
                </p>
                <p>
                  Finding the right college for you is an exciting process – and we’re eager to help. If you have any
                  questions about majors, financial aid, or student life at {acronym}, don’t hesitate to{' '}
                  <a
                    href="https://fireup.cmich.edu/register/requestinfo?utm_campaign=eab_lp22&utm_source=eab&utm_medium=lp&utm_content=request-info"
                    target="_blank"
                    rel="noreferrer"
                  >
                    get in touch.
                  </a>{' '}
                  Ready to submit your application? We can help with that, too. Our admissions counselors are happy to
                  walk you through{' '}
                  <a
                    href="https://www.cmich.edu/admissions-aid?utm_campaign=eab_lp22&utm_source=eab&utm_medium=lp&utm_content=admissions-aid"
                    target="_blank"
                    rel="noreferrer"
                  >
                    your next steps.
                  </a>
                </p>
                <p>
                  It’s one thing for us to tell you about how fired up we are about our outstanding student experience,
                  but it’s another for you to see it for yourself. We invite you to{' '}
                  <a
                    href="https://www.cmich.edu/visit?utm_campaign=eab_lp22&utm_source=eab&utm_medium=lp&utm_content=visit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    schedule a visit
                  </a>{' '}
                  as soon as possible and explore {acronym} in person.
                </p>
                <p>We look forward to showing you around!</p>
                <p className="thanks-by">{acronym} Undergraduate Admissions Team </p>
              </div>
              <StaticImage
                src="../assets/cmu-thanks-for-your-interest.jpg"
                alt="Thanks for your interest in CMU"
                className="hero-img"
              />
            </Container>
            <section
              className="
          wrapper centered
        offer-section
        "
            >
              <div className="row row-two-column  css-lrqkzw">
                <div className="first-column css-11t2kt2">
                  <div className="text-section">
                    <a
                      alt="Launch Experience"
                      href="https://www.youvisit.com/#/vte/?data-platform=v&data-link-type=immersive&data-inst=60804&data-image-width=100%&data-image-height=100%&"
                    >
                      Launch Experience
                    </a>
                  </div>
                </div>
                <div className="second-column css-11t2kt2">
                  <div className="text-section">
                    <p>
                      <strong>See how we do at CMU.</strong> Our students will take you on a quick tour of our campus
                      and facilities.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </Layout>
      <script async="async" defer="defer" src="https://www.youvisit.com/tour/Embed/js3" />
      <ModalContainer />
      <Footer />
    </>
  )
}

export default Thanks
